import * as React from "react";
import Navbar from "../sections/Navbar/Navbar";
import "../styles/index.css";
import "../styles/colour.css";
import "../styles/fonts.css";
// @ts-ignore
import caseStudiesImg from "../assets/img/old/caseStudies.png";
// @ts-ignore
import philosopyImg from "../assets/img/old/philosophy.png";

import Footer from "../sections/Footer";
import ImgContentTemplateRed from "../components/old/ImgContentTemplateRed/ImgContentTemplateRed";
import CoreTeam from "../sections/old/CoreTeam/CoreTeam";
import CaseVideos from "../sections/old/CaseVideos/CaseVideos";
import WhatYouGetTemplate from "../components/old/WhatYouGetTemplate/WhatYouGetTemplate";

// @ts-ignore
import certifiedIcon from "../assets/img/features/certified.svg";
// @ts-ignore
import Seo from "../utils/seo";
import CompaniesStudy from "../sections/old/CompaniesStudy/CompaniesStudy";
// @ts-ignore
import LayoutFr from "../layouts";
import WhatFr from "../sections/WhatFr";
import Leaders from "../sections/LeadersFr";
const CaseStudiesPage = () => {
  return (
    <>
      <Seo title="Coachello - Case Studies" />
      <LayoutFr>
        <ImgContentTemplateRed
          img={caseStudiesImg}
          redTitle="Études de cas"
          titleE={
            <h1>
              Voici ce que disent nos clients et leurs talents.
            </h1>
          }
        />
        <CompaniesStudy />
        {/*<CaseVideos/>*/}
        <WhatFr />
        <Leaders />
      </LayoutFr>
    </>
  );
};

export default CaseStudiesPage;
